import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";

import Lottie from "lottie-react";

import cow from "../../assets/lottie/cow.json";
import searchBackground from "../../assets/searchBackgroundnew.jpeg";

export default function SearchPage() {
  const [search, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [distance, setDistance] = useState(0.5);
  const [postcode, setPostcode] = useState("");
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [fromLong, setFromLong] = useState(0);
  const [fromLat, setFromLat] = useState(0);

  useEffect(() => {
    if (navigator.geolocation) {
      if (useCurrentLocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          setFromLat(pos.coords.latitude);
          setFromLong(pos.coords.longitude);
        });
      } else {
        setFromLat(0);
        setFromLong(0);
      }
    }
  }, [useCurrentLocation]);

  if (search) {
    const url = new URL("https://www.example.com/");

    url.searchParams.set("searchTerm", searchTerm);
    url.searchParams.set("distance", distance.toString());
    url.searchParams.set("postcode", postcode);
    if (useCurrentLocation) {
      url.searchParams.set("fromLat", fromLat.toString());
      url.searchParams.set("fromLong", fromLong.toString());
    }

    return <Navigate to={`/search?${url.searchParams.toString()}`} />;
  }

  return (
    <Grid
      container
      sx={{
        background: `url(${searchBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 2,
        minHeight: "calc( 100vh - 90px)",
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        margin="auto"
        sx={{
          backgroundColor: "rgba(0,0,0,0.8)",
          p: 4,
          borderRadius: 5,
          color: "white",
          "*": {
            color: "rgb(255,255,255) !important",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "100px",
            margin: "auto",
          }}
        >
          <Lottie animationData={cow} />
        </Box>
        <Typography
          textAlign={"center"}
          fontWeight="bold"
          variant="h4"
          p={2}
          color={"#3d3522"}
        >
          Find a tasty vegan meal near you today!
        </Typography>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Find a restaurant by name, cuisine, or menu item..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={6}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                label="Your postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={useCurrentLocation}
                    onChange={(e) => setUseCurrentLocation(e.target.checked)}
                  />
                }
                label="Use current location"
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Distance</InputLabel>
              <Select
                variant="outlined"
                value={distance}
                label="Distance"
                onChange={(e) => setDistance(+e.target.value)}
              >
                <MenuItem value={0.5}>0.5 miles</MenuItem>
                <MenuItem value={1}>1 miles</MenuItem>
                <MenuItem value={3}>3 miles</MenuItem>
                <MenuItem value={5}>5 miles</MenuItem>
                <MenuItem value={10}>10 miles</MenuItem>
                <MenuItem value={20}>20 miles</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSearch(true)}
            >
              FEED ME
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
