import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    [key: string]: any;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    [key: string]: any;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#00938e",
      contrastText: "white",
    },
    secondary: {
      light: "#daf542",
      main: "#daf542",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          boxShadow: "none",
          borderRadius: "20px",
        },
        containedPrimary: {
          background: "#346beb",
        },
        containedSecondary: {
          "&:hover": {
            background: "#00ccaa",
          },
          background: "#6136EC",
          color: "white",
        },
      },
    },
  },
});
