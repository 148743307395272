import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/logo.png";

const Logo = styled.img`
  && {
    width: 120px;
    object-fit: contain;
  }
`;

export default function () {
  return (
    <Link to="/">
      <Logo src={logo} />
    </Link>
  );
}
