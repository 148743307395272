import React, { useEffect, useState } from "react";

import { Add, AddAPhoto, AddPhotoAlternate } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  Dialog,
  CircularProgress,
  Alert,
  Chip,
  unstable_useId,
} from "@mui/material";

import {
  Dish,
  Restaurant,
  loadRestaurants,
  RestaurantPayload,
  saveRestaurant as onRestaurantSave,
} from "./state";
import store, { RootState } from "../../state";
import { useSelector } from "react-redux";
import moment from "moment";

export default function AdminPage() {
  const [selectedRestaurant, setSelectedRestaurant] = useState(0);
  const [showCreateRestaurant, setShowCreateRestaurant] = useState(false);

  const { items, error, loading, save } = useSelector(
    (state: RootState) => state.adminPage
  );

  useEffect(() => {
    store.dispatch(loadRestaurants());
  }, []);

  useEffect(() => {
    if (save.success) {
      window.location.reload();
    }
  }, [save.success]);

  const onRestaurantAdd = () => setShowCreateRestaurant(true);

  const saveRestaurant = (restaurant: RestaurantPayload) => {
    store.dispatch(onRestaurantSave(restaurant));
  };

  return (
    <Grid container textAlign="center">
      <Grid item xs={12} md={8}>
        <Box p={2}>
          {loading && <CircularProgress />}
          {error && (
            <Alert severity="error">
              <Typography>Error loading your restuarants</Typography>
            </Alert>
          )}
        </Box>
        <Box p={2}>
          <Typography variant="h4">My Restaurants</Typography>
        </Box>

        <Box p={2}>
          <Box>
            <Button onClick={onRestaurantAdd}>
              <Add />
              Add new restaurant
            </Button>
          </Box>
          {items.map((r) => {
            return (
              <RestaurantCell
                restaurant={r}
                onClick={() => setSelectedRestaurant(r.id)}
              />
            );
          })}
        </Box>
      </Grid>
      {selectedRestaurant !== 0 && (
        <RestaurantModal
          saveRestaurant={saveRestaurant}
          restaurant={items.find((r) => r.id === selectedRestaurant)}
          onClose={() => setSelectedRestaurant(0)}
        />
      )}
      {showCreateRestaurant && (
        <RestaurantModal
          saveRestaurant={saveRestaurant}
          createMode
          onClose={() => setShowCreateRestaurant(false)}
        />
      )}
    </Grid>
  );
}

const RestaurantCell = ({
  restaurant,
  onClick,
}: {
  restaurant: Restaurant;
  onClick: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        margin: " 0",
        display: "flex",
        p: 2,
        // justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
          background: "whitesmoke",
        },
      }}
    >
      <img
        src={restaurant.img}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
          width: "50px",
          height: "50px",
        }}
        // onError={({ currentTarget }) => {
        //   currentTarget.onerror = null; // prevents looping
        //   currentTarget.src = fallbackimage;
        // }}
      />
      <Box sx={{ margin: "auto 10px" }}>
        <Typography fontWeight={"bold"}>{restaurant.name}</Typography>
      </Box>
    </Box>
  );
};

const RestaurantModal = ({
  createMode,
  restaurant,
  onClose,
  saveRestaurant,
}: {
  createMode?: true;
  restaurant?: Restaurant;
  onClose: () => void;
  saveRestaurant: (details: RestaurantPayload) => void;
}) => {
  const [details, setDetails] = useState({
    id: 0,
    phone: "",
    postcode: "",
    name: "",
    description: "",
    img: "",
    address1: "",
    address2: "",
    website: "",

    dishes: [] as Restaurant["dishes"],
  });

  const [previewImage, setPreviewImage] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [selectedDish, setSelectedDish] = useState<Dish | null>(null);
  const [createNewDish, setCreateNewDish] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const formValid =
    (details.website === "" || details.website.startsWith("https://")) &&
    details.postcode;

  useEffect(() => {
    if (!createMode && restaurant) {
      setDetails({
        id: restaurant.id,
        phone: restaurant.phone,
        postcode: restaurant.postcode,
        name: restaurant.name,
        description: restaurant.description,
        address1: restaurant.address1,
        address2: restaurant.address2,
        website: restaurant.website,
        img: restaurant.img,
        dishes: restaurant.dishes,
      });
    }
  }, []);

  const updateField = (field: keyof typeof details, value: any) => {
    setChangesMade(true);
    setDetails({
      ...details,
      [field]: value,
    });
  };

  const insertDish = (dish: Dish) => {
    setDetails({
      ...details,
      dishes: [...details.dishes, dish],
    });
    setChangesMade(true);
    setSelectedDish(null);
    setCreateNewDish(false);
  };

  const editDish = (dish: Dish) => {
    const updatedDishes = details.dishes.map((d) => {
      if (d.id === dish.id) {
        return dish;
      }
      return d;
    });

    updateField("dishes", updatedDishes);
    setSelectedDish(null);
    setCreateNewDish(false);
  };

  return (
    <Dialog open onClose={onClose}>
      {changesMade && (
        <Box
          sx={{
            position: "fixed",
            width: "calc( 100% - 20px)",
            top: "20px",
            left: "10px",
            zIndex: 10,
          }}
        >
          <Alert severity="info">
            <Typography>Attention: You have unsaved changes</Typography>
          </Alert>
        </Box>
      )}
      <Box p={2}>
        <Box p={2}>
          <Typography variant="h4">
            {createMode ? "Add a restaurant" : "Edit restaurant"}
          </Typography>
        </Box>
        <Box p={2}>
          <img
            src={
              previewImage
                ? previewImage
                : restaurant
                ? restaurant.img
                : undefined
            }
            style={{
              width: "300px",
              display: "block",
              margin: "20px auto",
            }}
          />

          {!previewImage && (
            <Button variant="contained" component="label">
              {restaurant ? "Update photo" : "Add photo"}
              <AddPhotoAlternate />
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e) => {
                  if (!e.target || !e.target.files) {
                    return;
                  }
                  const file = e.target.files[0];

                  const objectUrl = URL.createObjectURL(file);
                  setPreviewImage(objectUrl);
                  setSelectedImage(file);
                  updateField("img", file);
                }}
              />
            </Button>
          )}
          {previewImage && (
            <Button
              variant="contained"
              onClick={() => {
                setPreviewImage(undefined);
                setSelectedImage(undefined);
                updateField("img", "");
              }}
            >
              CANCEL
            </Button>
          )}
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Restaurant name"
            value={details.name}
            onChange={(e) => updateField("name", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Phone number"
            value={details.phone}
            onChange={(e) => updateField("phone", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Description"
            value={details.description}
            onChange={(e) => updateField("description", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Postcode"
            value={details.postcode}
            error={!details.postcode}
            onChange={(e) => updateField("postcode", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Website"
            value={details.website}
            error={
              !details.website ? false : !details.website.startsWith("https://")
            }
            onChange={(e) => updateField("website", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Address 1"
            value={details.address1}
            onChange={(e) => updateField("address1", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Address 2"
            value={details.address2}
            onChange={(e) => updateField("address2", e.target.value)}
          />
        </Box>

        {restaurant !== undefined && (
          <Dishes
            dishes={details.dishes}
            onDishClick={setSelectedDish}
            addItem={() => setCreateNewDish(true)}
          />
        )}

        <Box px={2}>
          <Button
            disabled={!formValid}
            variant="contained"
            onClick={() =>
              saveRestaurant({
                ...(restaurant ? { id: restaurant.id } : {}),
                phone: details.phone,
                postcode: details.postcode,
                name: details.name,
                description: details.description,
                img: details.img,
                dishes: details.dishes,
                address1: details.address1,
                address2: details.address2,
                website: details.website,
              })
            }
          >
            SAVE CHANGES
          </Button>
        </Box>
        {selectedDish && (
          <DishModal
            dish={selectedDish}
            onClose={() => setSelectedDish(null)}
            onSaveClick={editDish}
          />
        )}
        {createNewDish && (
          <DishModal
            onClose={() => {
              setSelectedDish(null);
              setCreateNewDish(false);
            }}
            onSaveClick={insertDish}
          />
        )}
      </Box>
    </Dialog>
  );
};

const Dishes = ({
  dishes,
  onDishClick,
  addItem,
}: {
  dishes: Restaurant["dishes"];
  onDishClick: (dish: Dish) => void;
  addItem: () => void;
}) => {
  //todo: edit dishes, add new dish
  return (
    <Box p={2} maxWidth="350px">
      <Box>
        <Typography variant="h6">Dishes</Typography>
      </Box>
      <Box>
        {dishes.map((d) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                "&:hover": {
                  background: "whitesmoke",
                },
              }}
              onClick={() => onDishClick(d)}
            >
              <img
                src={
                  "string" === typeof d.thumbnail
                    ? d.thumbnail
                    : URL.createObjectURL(d.thumbnail)
                }
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                  margin: "auto 10px",
                }}
              />
              <Box p={1}>
                <Typography fontWeight={"bold"}>{d.title}</Typography>
                <Typography>{d.description}</Typography>
                <Typography>{d.price}</Typography>
                {d.tags.map((t) => {
                  return <Chip label={t} />;
                })}
              </Box>
            </Box>
          );
        })}
        <Box
          onClick={addItem}
          sx={{
            cursor: "pointer",
            "&:hover": {
              background: "whitesmoke",
            },
            textAlign: "center",
            flexDirection: "column",
            p: 2,
          }}
        >
          <Add />
          <Typography textAlign={"center"}>Add new menu item</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const DishModal = ({
  dish,
  onClose,
  onSaveClick,
}: {
  dish?: Dish;
  onClose: () => void;
  onSaveClick: (dish: Dish) => void;
}) => {
  const [tag, setTag] = useState("");
  const [previewImage, setPreviewImage] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [details, setDetails] = useState<Dish>({
    id: moment().unix() + "-new",
    title: "",
    description: "",
    price: 0,
    thumbnail: "",
    tags: [] as string[],
  });

  useEffect(() => {
    if (dish) {
      setDetails(dish);
    }
  }, []);

  const addTag = () => {
    setDetails({
      ...details,
      tags: [...details.tags, tag],
    });
    setTag("");
  };
  const deleteTag = (idx: number) => {
    setDetails({
      ...details,
      tags: details.tags.filter((_, i) => idx !== i),
    });
  };

  const updateField = (field: keyof typeof details, value: any) => {
    setDetails({
      ...details,
      [field]: value,
    });
  };

  const makeThumbnail = () => {
    if (previewImage) {
      return previewImage;
    }
    if (dish && dish.thumbnail) {
      if (typeof dish.thumbnail === "string") {
        return dish.thumbnail;
      } else {
        return URL.createObjectURL(dish.thumbnail);
      }
    }
    return "";
  };

  console.log(makeThumbnail());

  return (
    <Dialog open onClose={onClose}>
      <Box p={2}>
        <Box p={2}>
          <Typography variant="h4">
            {!dish ? "Add a dish" : "Edit dish"}
          </Typography>
        </Box>
        <Box p={2}>
          <img
            src={makeThumbnail()}
            style={{
              width: "300px",
              display: "block",
              margin: "20px auto",
            }}
          />

          {!previewImage && (
            <Button variant="contained" component="label">
              {dish ? "Update photo" : "Add photo"}
              <AddPhotoAlternate />
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e) => {
                  if (!e.target || !e.target.files) {
                    return;
                  }
                  const file = e.target.files[0];

                  const objectUrl = URL.createObjectURL(file);
                  setPreviewImage(objectUrl);
                  setSelectedImage(file);
                  updateField("thumbnail", file);
                }}
              />
            </Button>
          )}
          {previewImage && (
            <Button
              variant="contained"
              onClick={() => {
                setPreviewImage(undefined);
                setSelectedImage(undefined);
              }}
            >
              CANCEL
            </Button>
          )}
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Dish name"
            value={details.title}
            onChange={(e) => updateField("title", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Description"
            value={details.description}
            onChange={(e) => updateField("description", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            label="Price"
            value={details.price}
            onChange={(e) => updateField("price", e.target.value)}
          />
        </Box>
        <Box p={2}>
          <TextField
            label="Tags"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </Box>
        <Box px={2}>
          <Button onClick={addTag} variant="outlined" disabled={tag.length < 3}>
            Add tag
          </Button>
        </Box>
        <Box p={2} flexWrap="wrap" maxWidth="300px">
          {details.tags.map((t, idx) => (
            <Chip label={t} onDelete={(t) => deleteTag(idx)} />
          ))}
        </Box>

        <Box px={2}>
          <Button
            variant="contained"
            onClick={() =>
              onSaveClick({
                id: details.id,
                title: details.title,
                description: details.description,
                thumbnail: details.thumbnail,
                price: details.price,
                tags: details.tags,
              })
            }
          >
            SAVE CHANGES
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
