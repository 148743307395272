import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api";
import { config } from "../../configVars";

export type Dish = {
  id: number | string;
  title: string;
  description: string;
  price: number;
  thumbnail: string | File;
  tags: string[];
};

export type Restaurant = {
  id: number;
  phone: string;
  name: string;
  postcode: string;
  description: string;
  img: string;
  tags: string[];
  address1: string;
  address2: string;
  website: string;
  dishes: Dish[];
};

// types
export type State = {
  items: Restaurant[];
  loading: boolean;
  error: boolean;
  save: {
    loading: boolean;
    success: boolean;
    error: boolean;
  };
};

const initialState: State = {
  items: [],
  loading: false,
  error: false,
  save: {
    loading: false,
    success: false,
    error: false,
  },
};

// thunks
export const loadRestaurants = createAsyncThunk<Restaurant[]>(
  "adminPage/loadRestaurants",
  async () => {
    const url = `${config.apiUrl}/restaurant`;

    const { data } = await axios.get(url);

    return data;
  }
);

export type RestaurantPayload = Omit<Restaurant, "tags" | "id" | "img"> & {
  id?: number;
  img: File | string;
};
export const saveRestaurant = createAsyncThunk<void, RestaurantPayload>(
  "adminPage/saveRestaurant",
  async (restaurant) => {
    const url = `${config.apiUrl}/restaurant${
      restaurant.id ? `/${restaurant.id}` : ""
    }`;

    const formData = new FormData();
    for (const key of Object.keys(restaurant)) {
      //@ts-ignore
      let value = restaurant[key];
      formData.set(key, value);
    }

    if (restaurant.id) {
      await axios.put(url, formData, {
        headers: { "Content-Type": "application/json" },
      });
    } else {
      await axios.post(url, formData, {
        headers: { "Content-Type": "application/json" },
      });
    }

    for (const dish of restaurant.dishes) {
      const newDish = `${dish.id}`.includes("new");

      const url = `${config.apiUrl}/dish${!newDish ? `/${dish.id}` : ""}`;

      const body = new FormData();
      body.set("restaurantId", `${restaurant.id}`);
      for (const key of Object.keys(dish)) {
        //@ts-ignore
        let value = dish[key];
        if (Array.isArray(value)) {
          value = JSON.stringify(value);
        }
        body.set(key, value);
      }


      if (newDish) {
        await axios.post(url, body, {
          headers: { "Content-Type": "application/json" },
        });
      } else {
        await axios.put(url, body, {
          headers: { "Content-Type": "application/json" },
        });
      }
    }
  }
);

// slice
export const adminPageReducer = createSlice({
  name: "adminPage",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // load content
    builder.addCase(loadRestaurants.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.items = [];
    });
    builder.addCase(loadRestaurants.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(loadRestaurants.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    // save restaurant
    builder.addCase(saveRestaurant.pending, (state, action) => {
      state.save.loading = true;
      state.save.success = false;
      state.save.error = false;
    });
    builder.addCase(saveRestaurant.fulfilled, (state, action) => {
      state.save.loading = false;
      state.save.success = true;
    });
    builder.addCase(saveRestaurant.rejected, (state, action) => {
      state.save.loading = false;
      state.save.error = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const { actions } = adminPageReducer;

export default adminPageReducer.reducer;
