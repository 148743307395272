import { Call, East, ExpandMore, Language, West } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../state";
import { actions, delay, loadResults } from "./state";
import Map from "../../components/Map";
import DistanceBlock from "../../components/DistanceBlock";
import Lottie from "lottie-react";

import cow from "../../assets/lottie/cow.json";

type Props = {};

export default function ResultsPage() {
  const { results, delayDone } = useSelector(
    (state: RootState) => state.resultsPage
  );

  const [expandedRestaurants, setExpandedRestaurants] = useState<{
    [key: number]: boolean;
  }>({});

  const searchParams = window.location.search;
  const qs = QueryString.parse(searchParams.slice(1));

  useEffect(() => {
    store.dispatch(loadResults({ searchParams, limit: 50, offset: 0 }));
    store.dispatch(delay());
    setTimeout(() => {
      document.querySelector("#main-content")!.scrollTo(0, 0);
    }, 1000);
  }, []);

  if (results.loading || !delayDone) {
    return (
      <Grid container>
        <Grid item xs={12} textAlign="center">
          <Box
            sx={{
              maxWidth: "200px",
              margin: "auto",
            }}
          >
            <Lottie animationData={cow} />
          </Box>
          <Box p={2}>
            <Typography fontWeight={"bold"}>
              One Moo-ment... We're just loading your restaurants
            </Typography>
          </Box>
          {/* <CircularProgress size={"100px"} /> */}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container p={2} maxWidth="1000px" margin="auto">
      <Grid item>
        <Button href="/" variant="contained" color="secondary">
          <West />
          Go back
        </Button>
      </Grid>
      <Grid item xs={12} padding="30px">
        <Typography variant="h4" textAlign={"center"}>
          <>
            Showing vegan dishes{` `}
            {qs.searchTerm ? `matching "${qs.searchTerm}" ` : null}
            within {qs.distance}
            mi of {qs.useCurrentLocation ? "me" : qs.postcode}
          </>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {results.items.map((result) => {
          return (
            <Result
              result={result}
              expanded={expandedRestaurants[result.id]}
              setExpandedRestaurants={setExpandedRestaurants}
              expandedRestaurants={expandedRestaurants}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

const Result = ({
  result,
  expanded,
  setExpandedRestaurants,
  expandedRestaurants,
}: {
  expandedRestaurants: { [key: string]: boolean };
  setExpandedRestaurants: (value: { [key: string]: boolean }) => void;
  result: any;
  expanded: boolean;
}) => {
  return (
    <Accordion
      sx={{ borderRadius: "10px", my: "5px" }}
      expanded={expanded}
      onChange={() =>
        setExpandedRestaurants({
          ...expandedRestaurants,
          [result.id]: !expanded,
        })
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          "&:hover": {
            background: "whitesmoke",
          },
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            {/* <img
              src={result.img}
              style={{
                width: "75px",
                height: "75px",
                borderRadius: "10px",
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://static1.bigstockphoto.com/0/1/2/large1500/210018319.jpg";
              }}
            /> */}
            <Box p={2}>
              <Typography fontWeight="bold" variant="h5">
                {result.name}
              </Typography>
              <DistanceBlock distance={result.distance} />
            </Box>
          </Box>

          {/* <Box>
            {result.tags.map((t: string) => (
              <Chip label={t} />
            ))}
          </Box> */}
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box>
          <Typography>{result.description}</Typography>
        </Box>
        <Box py={2}>
          {result.number && (
            <Button
              variant={"outlined"}
              onClick={() => {}}
              style={{
                backgroundColor: "#539b53",
                color: "white",
                border: "none",
                margin: "2px",
              }}
            >
              <Call style={{ color: "white" }} />
              CALL
            </Button>
          )}
          {result.website && (
            <Button
              variant={"outlined"}
              href={result.website}
              target="_blank"
              style={{
                backgroundColor: "#539b53",
                color: "white",
                border: "none",
                margin: "2px",
              }}
            >
              <Language style={{ color: "white" }} />
              WEBSITE
            </Button>
          )}
        </Box>

        <Box>
          <Typography variant={"h6"}>Dishes</Typography>
        </Box>
        {result.dishes.map((m: any) => (
          <MenuItem result={m} />
        ))}
        {/* <Map long={result.longitude} lat={result.latitude} /> */}
        <Box>
          <Typography variant={"h6"}>Location</Typography>
          <Typography>{result.address1}</Typography>
          <br></br>
          <Typography>Open in</Typography>
          <Box
            sx={{
              flexDirection: "row",
            }}
          >
            <Button
              sx={{
                margin: "2px",
              }}
              href={`http://maps.apple.com/?sll=${result.latitude},${result.longitude}&q=${result.name}`}
              variant="contained"
            >
              Apple maps
            </Button>

            <Button
              sx={{
                margin: "2px",
              }}
              href={`https://citymapper.com/directions?endcoord=${
                result.latitude
              }%2C${result.longitude}&endname=${encodeURIComponent(
                result.name
              )}`}
              variant="contained"
            >
              City Mapper
            </Button>

            <Button
              sx={{
                margin: "2px",
              }}
              href={`https://www.google.com/maps/search/?api=1&query=${result.latitude}%2C${result.longitude}`}
              variant="contained"
            >
              Google maps
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const MenuItem = ({ result }: { result: any }) => {
  return (
    <Box>
      <Box
        sx={{
          px: 2,
          my: "5px",
          display: "flex",
          background: "white",
          borderRadius: "10px",
          justifyContent: "flex-start",
        }}
      >
        {/* <img
          src={result.thumbnail}
          style={{
            width: "75px",
            height: "75px",
            margin: "auto 10px",
            borderRadius: "10px",
          }}
        /> */}
        <Box sx={{ p: 2 }}>
          <Typography fontWeight="bold">
            {result.title} - £{result.price}
          </Typography>
          <Typography fontSize={"12px"}>{result.description}</Typography>

          <Box>
            {result.tags.map((t: string) => (
              <Chip label={t} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
