import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Wrapper from "./components/Wrapper";

import SearchPage from "./containers/SearchPage/index";
import ResultsPage from "./containers/ResultsPage/index";
import LoginPage from "./containers/LoginPage/index";
import LogoutPage from "./containers/LogoutPage/index";
import AdminPage from "./containers/AdminPage/index";
import { ProtectedRoute } from "./components/ProtectedRoute";

function App() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="/search" element={<ResultsPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
