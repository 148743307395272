import { configureStore } from "@reduxjs/toolkit";
import resultsPageReducer from "./containers/ResultsPage/state";
import loginPageReducer from "./containers/LoginPage/state";
import adminPageReducer from "./containers/AdminPage/state";

// ...
const store = configureStore({
  reducer: {
    resultsPage: resultsPageReducer,
    loginPage: loginPageReducer,
    adminPage: adminPageReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
