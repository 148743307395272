import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import React from "react";
import Logo from "./Logo";

export default function SplashPage() {
  const theme = useTheme();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "fixed",
        left: 0,
        top: 0,
      }}
    >
      <Box
        sx={{
          width: "80%",
          m: "auto",
          textAlign: "center",
        }}
      >
        <Logo />
        <Typography p={2} color="white" variant="h4">
          Hang tight, we're preparing your account
        </Typography>
        <LinearProgress color="secondary" />
      </Box>
    </div>
  );
}
