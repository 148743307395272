import {
  Grid,
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Typography,
} from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import store, { RootState } from "../../state";
import { login, register } from "./state";

export default function LoginPage() {
  useEffect(() => {
    // if token, try to login, if
  }, []);

  const { loading, error, token } = useSelector(
    (state: RootState) => state.loginPage
  );

  const [isRegister, setIsRegister] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  if (token) {
    return <Navigate to="/admin" />;
  }

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isRegister) {
      store.dispatch(register({ email, password }));
    } else {
      store.dispatch(login({ email, password }));
    }
  };
  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        background: "white",
        minHeight: "100%",
      }}
    >
      <Grid item xs={12} md={6}>
        <form
          onSubmit={(e) => submit(e)}
          style={{
            marginTop: "50px",
            marginLeft: "10px",
            marginRight: "10px",
            padding: "20px",
            borderRadius: "20px",
            boxShadow: "0px 10px 20px rgba(0,0,0,0.5)",
            background: "white",
          }}
        >
          <Typography variant="h4" textAlign="center">
            {isRegister ? `Create your account` : `Welcome back`}
          </Typography>
          {error && (
            <Box>
              <Alert severity="error">
                {isRegister
                  ? "Error creating account"
                  : "Please check username/password "}
              </Alert>
            </Box>
          )}
          <Box p={2}>
            <TextField
              required
              fullWidth
              label="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box p={2}>
            <TextField
              required
              fullWidth
              label="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          {isRegister && (
            <Box p={2}>
              <TextField
                required
                fullWidth
                type="password"
                label="confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Box>
          )}

          <Box p={2} textAlign="center">
            <Button onClick={() => setIsRegister(!isRegister)}>
              {isRegister
                ? "I already have an account"
                : "I need to register an account"}
            </Button>
          </Box>
          {loading && (
            <Box p={2} textAlign="center">
              <CircularProgress size="50px" />
            </Box>
          )}
          {!loading && (
            <Box p={2} textAlign="center">
              <Button type="submit" variant="contained">
                SUBMIT
              </Button>
            </Box>
          )}
        </form>
      </Grid>
    </Grid>
  );
}
