import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api";
import { config } from "../../configVars";

type Result = {
  id: number;
  phone: string;
  name: string;
  distance: number;
  longitude: number;
  latitude: number;
  description: string;
  img: string;
  tags: string[];
  dishes: {
    id: number;
    title: string;
    description: string;
    price: string;
    thumbnail: string;
    tags: string[];
  }[];
};
// types
export type State = {
  results: {
    loading: boolean;
    error: boolean;
    items: Result[];
    hasMore: boolean;
  };
  delayDone: boolean;
};

const initialState: State = {
  results: {
    loading: false,
    error: false,
    items: [],
    hasMore: false,
  },
  delayDone: false,
};

// thunks
export const loadResults = createAsyncThunk<
  Result[],
  { searchParams: string; limit: number; offset: number }
>("resultsPage/loadResults", async ({ searchParams, limit, offset }) => {
  const url = `${config.apiUrl}/${searchParams}&limit=${limit}&offset=${offset}`;

  const { data } = await axios.get(url);

  return data;
});

export const delay = createAsyncThunk<void>("resultsPage/delay", async () => {
  await new Promise((res) => {
    setTimeout(() => res(null), 1000);
  });
  return;
});

// slice
export const resultsPageReducer = createSlice({
  name: "resultsPage",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // load content
    builder.addCase(loadResults.pending, (state, action) => {
      state.results.loading = true;
      state.results.error = false;
      state.results.items = [];
    });
    builder.addCase(loadResults.fulfilled, (state, action) => {
      state.results.loading = false;
      const newList = [...state.results.items, ...action.payload];
      const seen: { [key: string]: any } = {};
      state.results.items = newList.filter((result) => {
        if (seen[result.id.toString()]) {
          return false;
        } else {
          seen[result.id.toString()] = 1;
          return true;
        }
      });
      state.results.hasMore = action.payload.length == 50;
    });
    builder.addCase(loadResults.rejected, (state, action) => {
      state.results.loading = false;
      state.results.error = true;
      state.results.items = [];
    });
    // load content
    builder.addCase(delay.pending, (state, action) => {
      state.delayDone = false;
    });
    builder.addCase(delay.fulfilled, (state, action) => {
      state.delayDone = true;
    });
  },
});

// Action creators are generated for each case reducer function
export const { actions } = resultsPageReducer;

export default resultsPageReducer.reducer;
