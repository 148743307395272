import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api";
import { config } from "../../configVars";

// types
export type State = {
  token: string;
  loading: boolean;
  error: boolean;
};

const initialState: State = {
  token: "",
  loading: false,
  error: false,
};

// thunks
//login
export const login = createAsyncThunk<
  {
    token: string;
    userId: string;
    email: string;
  },
  { email: string; password: string }
>("loginPage/login", async ({ email, password }) => {
  const url = `${config.apiUrl}/login`;

  const { data } = await axios.post(
    url,
    { email, password },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return data;
});

// register
export const register = createAsyncThunk<
  string,
  { email: string; password: string }
>("loginPage/register", async ({ email, password }) => {
  const url = `${config.apiUrl}/register`;

  const { data } = await axios.post(
    url,
    { email, password },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return data;
});

// refresh
export const refresh = createAsyncThunk<
  {
    token: string;
    userId: string;
    email: string;
  },
  string
>("loginPage/refresh", async (token) => {
  const url = `${config.apiUrl}/refresh`;

  const { data } = await axios.post(
    url,
    { token },
    {
      headers: { "Content-Type": "application/json" },
    }
  );

  return data;
});

// slice
export const resultsPageReducer = createSlice({
  name: "resultsPage",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // load login
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.token = "";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.token;

      localStorage.setItem("token", action.payload.token);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      localStorage.removeItem("token");
    });
    //register
    builder.addCase(register.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.token = "";
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    //refresh
    builder.addCase(refresh.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.token = "";
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.token;
    });
    builder.addCase(refresh.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      localStorage.removeItem("token");
    });
  },
});

// Action creators are generated for each case reducer function
export const { actions } = resultsPageReducer;

export default resultsPageReducer.reducer;
