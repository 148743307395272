import { LocalHospitalTwoTone } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../state";
import { actions } from "../LoginPage/state";

const LogoutPage: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.loginPage);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("token");
    dispatch(actions.resetState());
  }, []);

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Box>
      <CircularProgress />
    </Box>
  );
};

export default LogoutPage;
