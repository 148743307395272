import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { refresh } from "../containers/LoginPage/state";
import store, { RootState } from "../state";
import SplashPage from "./SplashPage";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const localStorageToken = localStorage.getItem("token");
  const { token, loading, error } = useSelector(
    (state: RootState) => state.loginPage
  );

  const [delay, setDelay] = useState(false);

  useEffect(() => {
    if (!token && localStorage.getItem("token")) {
      store.dispatch(refresh(localStorage.getItem("token") as string));

      setDelay(true);
      setTimeout(() => {
        setDelay(false);
      }, 1000);
    }
  }, []);

  if (!localStorageToken || error) {
    return <Navigate to="/login" replace />;
  }

  if (loading || delay) {
    return <SplashPage />;
  }

  return <>{children}</>;
};
