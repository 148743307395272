import { GpsFixed } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const prettyDistance = (distance: number) => {
  let d = Math.round(distance / 0.05) * 0.05;
  if (d === 0) {
    return "<100m away";
  }
  return `${d.toFixed(2)} mi away`;
};
type Props = {
  distance: number;
};
export default function DistanceBlock({ distance }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <GpsFixed
        fontSize={"small"}
        sx={{
          margin: "auto 0",
          display: "inline",
        }}
      />
      <Typography fontSize={"10px"} margin="auto 5px" display="inline-block">
        {prettyDistance(distance)}
      </Typography>
    </Box>
  );
}
