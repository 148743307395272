import React from "react";
import styled from "styled-components";

import { Box, Button, Menu, MenuItem } from "@mui/material";
import Logo from "./Logo";
import { useSelector } from "react-redux";
import { RootState } from "../state";
import { Settings } from "@mui/icons-material";
import { Link } from "react-router-dom";

const WrapperComponent = styled.div`
  && {
    display: flex;
    flex-direction: row;
  }
`;

const Toolbar = styled.div`
  && {
    background: white;
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.14);
    z-index: 1000;
  }
`;

const Wrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { token } = useSelector((state: RootState) => state.loginPage);

  return (
    <WrapperComponent>
      <Toolbar>
        <div>
          <Logo />
        </div>

        {token ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Settings />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>
                <Link to="/logout">Logout</Link>
              </MenuItem>
            </Menu>
          </div>
        ) : null}
      </Toolbar>

      <Box
        sx={{
          height: "calc( 100vh - 80px)",
          width: "100%",
          overflow: "scroll",
          marginTop: "80px",
        }}
        id="main-content"
      >
        {children}
      </Box>
    </WrapperComponent>
  );
};

export default Wrapper;
